<template>
  <component
      :is="url ? 'a' : 'div'"
      :href="url"
      :target="urlTarget"
      class="campaign-banner"
      :style="{ 'background-color': `rgba(${backgroundRgba.r}, ${backgroundRgba.g}, ${backgroundRgba.b}, ${backgroundRgba.a})`, 'color': props.color }"
  >
    <div class="campaign-banner--content">
      <div class="campaign-banner--content-label">
        <span class="campaign-banner--content-label-long">
          <slot />
        </span>
        <span v-if="props.shortTitle" class="campaign-banner--content-label-short">
          {{ props.shortTitle }}
        </span>
      </div>
      <div
          v-if="countdown"
          class="campaign-banner--content-countdown"
          :style="{'border-color': props.color}"
      >
        {{ countdown }}
      </div>
    </div>
    <button
        v-if="url"
        class="campaign-banner--cta"
        :style="{'background-color': props.color}"
    >
      <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 5.5H11.6667" :stroke="props.featureColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M9 9.5L13 5.5L9 1.5" :stroke="props.featureColor" stroke-width="1.5" stroke-linecap="square"/>
        </svg>
      </svg>
    </button>
  </component>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";

  const countdown = ref(null);

  const props = defineProps({
    shortTitle: {
      type: String,
      required: false
    },
    featureColor: {
      type: String,
      required: true,
      validator(value) {
        return /^#([0-9A-F]{3}){1,2}$/i.test(value);
      }
    },
    color: {
      type: String,
      required: true,
      validator(value) {
        return /^#([0-9A-F]{3}){1,2}$/i.test(value);
      }
    },
    url: {
      type: String,
      required: true
    },
    urlTarget: {
      type: String,
      default: '_self',
      validator(value) {
        return ['_blank', '_parent', '_self', '_top'].includes(value);
      }
    },
    targetDate: {
      type: Date,
      default: null
    }
  });

  const backgroundRgba = computed(() => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(props.featureColor);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 0.9
    } : null;
  });

  onMounted(() => {
    if (!(props.targetDate instanceof Date)) {
      countdown.value = null;
      return;
    }

    const intervalId = setInterval(() => {
      const now = new Date();
      const diff = props.targetDate - now;

      if (diff <= 0) {
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor(((diff / 1000)) % 60);

      countdown.value = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  });
</script>

<style lang="scss">
  .campaign-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.375rem;
    flex-shrink: 0;
    width: 100%;
    height: 2.25rem;
    padding: 0 1rem 0 1.25rem;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    .campaign-banner--content {
      display: inline-flex;
      gap: 0.25rem;

      .campaign-banner--content-label {
        font-size: 0.825rem;
        font-weight: 600;
        letter-spacing: 0.008rem;
        order: 1;

        .campaign-banner--content-label-long {
          display: none;
        }

        .campaign-banner--content-label-short {
          display: block;
        }
      }

      .campaign-banner--content-countdown {
        font-size: 0.825rem;
        font-weight: 400;
        letter-spacing: 0.016rem;
        font-variant-numeric: tabular-nums;
        order: 2;
      }
    }

    @media screen and (min-width: 740px) {
      .campaign-banner--content {
        gap: 1.375rem;

        .campaign-banner--content-label {
          order: 2;
          font-weight: 500;

          .campaign-banner--content-label-long {
            display: block;
          }

          .campaign-banner--content-label-short {
            display: none;
          }
        }

        .campaign-banner--content-countdown {
          order: 1;
          border-radius: 2.25rem;
          border-width: 1px;
          border-style: solid;
          padding: 0 0.875rem 0 0.875rem;
        }
      }
    }

    .campaign-banner--cta {
      display: flex;
      height: 22px;
      padding: 0 0.875rem 0 0.875rem;
      align-items: center;
      gap: 0.75rem;
      border-radius: 0.75rem;

      svg {
        width: 15px;
        height: 10px;
      }
    }
  }
</style>